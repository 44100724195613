.wrapper {
  position: relative;
  background-color: #1B1B1B;
  color: white;
}

.bg {
  background: url('./bg.png') repeat;
  opacity: 0.17;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1600px) {
    max-width: 1536px;
  }
}

.left {
  flex: 1;
  position: relative;
  padding: 120px 120px 120px 0;

  .blueLine {
    background: #0494F8;
    width: 32px;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: 125px;
  }
}

.right {
  position: relative;
  flex: 0 0 400px;
  border-left: 1px solid #363636;
  padding: 120px 0 120px 202px;
  display: flex;
  flex-direction: column;

  @media (min-width: 1600px) {
    flex: 0 0 512px;
    padding-left: 255px;
  }
}

.line {
  background: #363636;
  position: absolute;
  top: 143px;
  left: 20px;
  width: 90px;
  height: 1px;

  @media (min-width: 1600px) {
    width: 120px;
    left: 30px;
  }
}

.graphic {
  position: absolute;
  top: 124px;
  left: 130px;

  @media (min-width: 1600px) {
    left: 180px;
  }
}

.title {
  color: white;
  font-size: 40px;
  font-weight: 500;
  line-height: 140%;
  max-width: 880px;

  @media (min-width: 1600px) {
    font-size: 50px;
  }
}

.text {
  margin: 45px 0 0 210px;
  color: #9B9B9B;
  font-size: 16px;
  font-weight: 400;
  line-height: 185%; /* 29.6px */

  p {
    margin: 0;
    padding: 0;
  }

  p + p {
    margin-top: 25px;
  }
}

.textBlock {
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 170%; /* 23.8px */
  text-transform: uppercase;

  & + & {
    margin-top: 50px;
  }

  &:last-child {
    margin-top: auto;
  }
}