.wrapper {
  position: relative;

  &.opened {
    color: white;
  }
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  border-left: 1px solid #E8EAEC;
  border-right: 1px solid #E8EAEC;

  @media (min-width: 1600px) {
    max-width: 1536px;
  }
}

.opened {
  div {
    border-color: rgba(255,255,255,0.5) !important;
  }

  .name, .year, .text, .title {
    color: white;
    text-shadow: 1px 1px 0 rgba(0,0,0,0.05);

    &::selection {
      background: #202020;
    }
  }

  .name {
    z-index: 3;
    right: 145px;
    top: 110px;
  }
}

.inner {
  position: relative;
  height: 100%;
  padding: 90px 0;

  @media (min-width: 1600px) {
    padding: 140px 0;
  }
}

.name {
  position: absolute;
  top: 100px;
  right: 0;
  color: #323232;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.42px;
  user-select: none;

  @media (min-width: 1600px) {
    top: 150px;
  }
}

.year {
  position: absolute;
  bottom: 90px;
  left: 0;
  color: #323232;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.42px;
  user-select: none;

  @media (min-width: 1600px) {
    bottom: 170px;
  }
}

.title {
  position: relative;
  font-size: 65px;
  font-weight: 500;
  line-height: 100px;
  letter-spacing: -1.6px;
  max-width: 850px;

  &::selection {
    background: #0494F9;
    color: white;
  }

  @media (min-width: 1600px) {
    font-size: 80px;
    line-height: 110px;
    max-width: 1200px;
  }
}

.text {
  position: relative;
  margin: 40px 0 0 270px;
  color: #595959;
  line-height: 185%;
  font-size: 16px;
  max-width: 550px;

  &::selection {
    background: #595959;
    color: white;
  }

  @media (min-width: 1600px) {
    margin: 60px 0 0 360px;
    font-size: 18px;
    max-width: 660px;
  }
}

.borders {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;

  & > div {
    z-index: -1;
    flex: 1;
  }

  & > div:first-child {
    border-top: 1px solid #E8EAEC;
    height: 246px;
    align-self: end;
  }

  & > div:nth-child(2) {
    border-top: 1px solid #E8EAEC;
    border-left: 1px solid #E8EAEC;
    border-right: 1px solid #E8EAEC;
    top: 100px;
    height: calc(100% - 100px);
    align-self: end;
  }

  & > div:nth-child(3) {
    border-top: 1px solid #E8EAEC;
    border-right: 1px solid #E8EAEC;
    height: 273px;
    align-self: end;
  }
  & > div:nth-child(4) {
    border-top: 1px solid #E8EAEC;
    height: 193px;
    align-self: end;
  }
  & > div:nth-child(5) {
    border-top: 1px solid #E8EAEC;
    border-left: 1px solid #E8EAEC;
    height: 330px;
    align-self: end;
  }
  & > div:nth-child(6) {
    z-index: 1;
    position: relative;
    border-left: 1px solid #E8EAEC;
    height: 100%;
    display: flex;
    align-items: flex-end;
  }

  &.opened > div:nth-child(6) {
    opacity: 0;
    z-index: -1;
  }
}

.blueButton {
  cursor: pointer;
  background: #0494F9;
  outline: none;
  border: none;
  padding: 25px 20px;
  color: white;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 1;
  transition: opacity 0.5s;

  &:hover {
    opacity: 0.8;
  }
}

.start {
  position: relative;
  margin: 65px 0 0 270px;
  width: 280px;

  @media (min-width: 1600px) {
    margin: 40px 0 0 360px;
  }
}

.backButton {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 280px;
  justify-content: center;
  z-index: 2;
}

.opened {
  & .start {
    background: #202020;
  }
}

.left {
  flex: 1;
}

.right {
  flex: 0 0 450px;
  //background: #0494F9;
}

.circle {
  width: 210px;
  height: 210px;
  border-radius: 50%;
  border: 1px solid #E8EAEC;
  position: absolute;
  left: 0;
  bottom: 435px;
  transform: translate(-50%, 50%);
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url('./bg.png') repeat;

  canvas {
    z-index: -1;
  }
}

.blueSmall {
  cursor: pointer;
  width: 100%;
  position: relative;
  height: 435px;

  & span {
    user-select: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: right;
    font-weight: 500;
    letter-spacing: 0.42px;
    color: white;
    text-transform: uppercase;
  }
}

.canvas {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.technologies {
  position: absolute;
  right: -1px;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 282px;
  background: url('./bg.png') repeat;
  background-color: #0494F9;

  & ul {
    list-style: none;
    margin: 190px 0 0 20px;

    li {
      color: #FFF;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      text-transform: uppercase;

      &::selection {
        background: #202020;
      }

      span {
        margin: 0 15px;

        &::selection {
          background: white;
          color: #0494F9;
        }
      }
    }

    li + li {
      margin-top: 30px;
    }
  }
}