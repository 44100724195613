.wrapper {
  height: 70px;
  background: white;
  padding: 24px 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(225, 225, 225, 0.90);
}

.logo {
  display: flex;
  align-items: center;
  user-select: none;
  flex: 0 0 120px;

  & span {
    margin-left: 15px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.3px;
  }
}

.menu {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  & a {
    color: inherit;
    text-decoration: none;
    font-weight: 500;
    white-space: nowrap;
  }

  & ul {
    margin:0;
    padding:0;
    display: flex;
  }

  & ul li {
    list-style:none;
    transition:0.5s;
  }

  & ul li + li {
    margin-left: 35px;
  }

  & ul li a {
    display: block;
    position: relative;
    text-decoration: none;
    transition: 0.5s;
  }

  & ul:hover li a {
    transform:scale(1.5);
    opacity: 0.2;
    filter:blur(5px);
  }

  & ul li a:hover {
    transform:scale(1.2);
    opacity:1;
    filter:blur(0);
    text-decoration:none;
    color:#fff;
  }

  & ul li a:before {
    content:'';
    position:absolute;
    top: 0;
    left: -3px;
    width:calc(100% + 6px);
    height:100%;
    background: #1B1B1B;
    transition:0.5s;
    transform-origin:right;
    transform:scaleX(0);
    z-index:-1;
  }

  & ul li a:hover:before{
    transition:transform 0.5s;
    transform-origin:left;
    transform:scaleX(1);
  }
}

.contacts {
  flex: 0 0 120px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & span {
    border-bottom: 1px solid #0494F9;
    padding-bottom: 6px;
  }

  & a {
    color: inherit;
    text-decoration: none;
    font-weight: 500;
    white-space: nowrap;
  }
}